import {observer} from "mobx-react";
import {SetStateAction, useContext, useState} from "react";
import styles from "./styles/AccordionTerrace.module.scss";
import FontAwesome from "../../../utilities/FontAwesome";
import {StoreContext} from "../../../../stores/StoreLoader";
import Markdown from "../../../utilities/Markdown";
import dynamic from "next/dynamic";

const DraggableAccordionSections = dynamic(() => import('./AccordionDragAndDrop'))
const AccordionItemEditor = dynamic(() => import('./AccordionItemEditor'))

const AccordionItem = observer((props: {
    item: IAccordionItem,
    index: number,
    blockObj: IAccordionTerrace,
    handleSave: () => void,
    handleCancel: () => void,
    isDragging: boolean,
    setIsDragging: SetStateAction<any>,
    reorder: boolean,
    setAccordionList: SetStateAction<any>,
    accordionList: IAccordionItem[],
    expanded: boolean,
    toggleExpansion: (index: number) => void,
}) => {
    const {userStore} = useContext(StoreContext);

    const [editing, setEditing] = useState(false);

    function onKeyDown(event) {
        if (event.key === 'Enter') {
            props.toggleExpansion(props.index);
        }
        else if (event.key === 'Escape' && props.expanded) {
            props.toggleExpansion(props.index);
        }
    }

    if (editing) {
        return <div key={props.index} className={styles.accordionItem}>
            <AccordionItemEditor
                blockObj={props.blockObj}
                index={props.index}
                item={props.item}
                handleSave={() => {
                    props.handleSave();
                    setEditing(false)
                }}
                handleCancel={() => {
                    props.handleCancel();
                    setEditing(false)
                }}
                accordionList={props.accordionList}
                setAccordionList={props.setAccordionList}
            />
        </div>
    } else if (props.reorder) {
        return <DraggableAccordionSections
            accordion_item={props.item}
            index={props.index}
            isDragging={props.isDragging}
            setIsDragging={props.setIsDragging}
            dragAxis={"y"}
            blockObj={props.blockObj}
            setAccordionList={props.setAccordionList}
            accordionList={props.accordionList}
        >
            <div key={props.index} className={styles.accordionItem}>
                <div className={styles.accordionItemTitle}>
                    <span ><FontAwesome prefix={"fas"} name={"fas fa-arrows-alt"}/> {props.item.title}</span>
                </div>
            </div>
        </DraggableAccordionSections>

    } else {
        return <div onKeyDown={onKeyDown} tabIndex={0} onClick={() => { props.toggleExpansion(props.index) } } key={props.index} className={styles.accordionItem}>
            <div className={styles.accordionItemTitle}>
                <div className={styles.fullWidthButton}>
                    <div>
                        <span>{props.item.title}</span>
                        {userStore.isEditor && !editing &&
                            <button key="edit" type="button" onClick={() => setEditing(!editing)}
                                    disabled={editing}
                                    title={editing ? "Click to save" : "Click to edit"}
                                    aria-label={editing ? "Click to save" : "Click to edit"}
                                    className={styles.editItemButton}>
                                <span className="fas fa-pencil-alt" role="presentation"/>
                            </button>}
                    </div>
                    <div className={styles.expandCollapseButton}>
                        {props.expanded ? <span><FontAwesome prefix={"fas"} fixedWidth name={'fa-xmark'} ariaHidden/></span> :
                            <span className={styles.rotate}><FontAwesome prefix={"fas"} fixedWidth name={'fa-xmark'}
                                                                         ariaHidden/></span>}
                        {!props.expanded ?
                            <span><FontAwesome prefix={"fas"} fixedWidth name={'fa-chevron-down'} ariaHidden/></span> :
                            <span className={styles.rotate}><FontAwesome prefix={"fas"} fixedWidth
                                                                         name={'fa-chevron-down'}
                                                                         ariaHidden/></span>}
                    </div>
                </div>
            </div>
            <div className={`${styles.accordionItemContent} ${props.expanded ? styles.open: styles.closed}`}>
                <Markdown allowLinks={true} withStyles={true} source={props.item.content}/>
            </div>
        </div>
    }
});

export default AccordionItem;
